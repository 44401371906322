import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { Box, Typography } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _userList, _roles } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useCampaignsContext } from 'src/components/campaigns';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
// types
import {
  IUserItem,
  IUserTableFilters,
  IUserTableFilterValue,
} from 'src/types/user';
import { useAuthContext } from 'src/auth/hooks';
//
import { useLocalStorage } from 'src/hooks/use-local-storage';
import UserTableRow from '../user-table-row';
import UserTableToolbar from '../user-table-toolbar';
import UserTableFiltersResult from '../user-table-filters-result';
import { UserInviteDialog } from '../user-invite-dialog';
import UserPendingTableRow from '../user-table-row-pending';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'sale', label: 'Sale' },
  { value: 'qualified', label: 'Qualified' },
  { value: 'lead', label: 'Lead' },
];

const TABS = [
  {
    value: 'active',
    label: (count: any) => (
      <Box sx={{ display: 'flex', gap: 2 }}>
        Active{' '}
        <Box
          sx={{
            borderRadius: '6px',
            background: 'rgba(34, 197, 94, 0.16)',
            p: '2px 6px',
            fontWeight: 700,
            fontSize: '12px',
            color: '#118D57',
          }}
        >
          {count}
        </Box>
      </Box>
    ),
    component: () => <Box>Active</Box>,
  },
  {
    value: 'pending',
    label: (count: any) => (
      <Box sx={{ display: 'flex', gap: 2 }}>
        Pending
        <Box
          sx={{
            borderRadius: '6px',
            background: 'rgba(255, 171, 0, 0.16)',
            p: '2px 6px',
            fontWeight: 700,
            fontSize: '12px',
            color: '#B76E00',
          }}
        >
          {count}
        </Box>
      </Box>
    ),
    component: () => <Box>Pending</Box>,
  },
];

const TABLE_HEAD = [
  { id: '', label: '', width: 120 },
  { id: 'fullname', label: 'Name' },
  { id: 'email', label: 'Email', width: 180 },
  { id: 'role', label: 'Role', width: 220 },
  { id: 'date', label: 'Date Added', width: 220 },
];

const TABLE_HEAD_ADMIN = [
  { id: '', label: '', width: 120 },
  { id: 'fullname', label: 'Name' },
  { id: 'email', label: 'Email', width: 180 },
  { id: 'role', label: 'Role', width: 220 },
  { id: 'date', label: 'Date Added', width: 220 },
  { id: '', label: '', width: 220 },
];

const TABLE_HEAD_PENDING = [
  { id: '', label: '', width: 120 },
  { id: 'email', label: 'Email', width: 220 },
  { id: 'role', label: 'Role', width: 220 },
  { id: 'date', label: 'Date Invited', width: 220 },
  { id: '', label: '', width: 120 },
];

const defaultFilters: IUserTableFilters = {
  leadInformation: '',
  updatedAt: [],
  status: 'all',
  firstName: '',
  lastName: '',
};

// ----------------------------------------------------------------------

export default function UserListView() {
  const {
    getUsersList,
    usersList,
    getUsersRequestsList,
    usersListRequests,
    getRolesList,
    deleteUser,
  } = useCampaignsContext();

  const { user: userInfo } = useAuthContext();

  const isAdmin =
    usersList.find((user: any) => user.email === userInfo?.email)?.role ===
    'Authenticated';

  useEffect(() => {
    getRolesList();
  }, [getRolesList]);

  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(_userList);

  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  useEffect(() => {
    if (userInfo?.account_id?.id) {
      getUsersRequestsList({ accountId: userInfo?.account_id?.id });
    }
  }, [getUsersRequestsList, userInfo?.account_id?.id]);

  const handleDeleteRow = useCallback(
    (id: string) => {
      deleteUser(id);
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData, deleteUser],
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter(
      (row) => !table.selected.includes(row.id),
    );
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router],
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  useEffect(() => {
    if (usersList && usersList.length > 0) {
      setTableData(
        usersList.map((user: any) => ({
          id: user.id || 'N/A',
          fullName: user.fullName || 'N/A',
          email: user.email || 'N/A',
          role: user.role || 'N/A',
          createdAt: user.createdAt || 'N/A',
        })),
      );
    }
  }, [usersList]);

  const invite = useBoolean();

  const [currentTab, setCurrentTab] = useLocalStorage('userList', 'active');

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(newValue);
    },
    [setCurrentTab],
  );

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {isAdmin ? (
          <CustomBreadcrumbs
            heading="User List"
            links={[{}]}
            sx={{
              mb: { xs: 3, md: 5 },
            }}
            action={
              <Button
                onClick={() => invite.onTrue()}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                New User
              </Button>
            }
          />
        ) : (
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          >
            Team Members List
          </Typography>
        )}

        <UserInviteDialog invite={invite} countPeople={usersList?.length} />

        <Card>
          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          {isAdmin && (
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              sx={{ pl: '20px' }}
            >
              {TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label(
                    tab.value === 'active'
                      ? dataFiltered?.length || 0
                      : usersListRequests.length || 0,
                  )}
                />
              ))}
            </Tabs>
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id),
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={
                    // eslint-disable-next-line no-nested-ternary
                    currentTab === 'pending' && isAdmin
                      ? TABLE_HEAD_PENDING
                      : isAdmin
                      ? TABLE_HEAD_ADMIN
                      : TABLE_HEAD
                  }
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id),
                    )
                  }
                  isAdmin={isAdmin}
                />

                <TableBody>
                  {currentTab === 'active' || !isAdmin
                    ? dataFiltered
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage,
                        )
                        .map((row) => (
                          <UserTableRow
                            key={row.id}
                            row={row}
                            selected={table.selected.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                            onDeleteRow={() => handleDeleteRow(row.id)}
                            onEditRow={() => handleEditRow(row.id)}
                            isAdmin={isAdmin}
                          />
                        ))
                    : usersListRequests
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage,
                        )
                        .map((row: any) => (
                          <UserPendingTableRow
                            key={row.id}
                            row={row}
                            selected={table.selected.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                            onDeleteRow={() => handleDeleteRow(row.id)}
                            onEditRow={() => handleEditRow(row.id)}
                            isAdmin={isAdmin}
                          />
                        ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      tableData.length,
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={
              currentTab === 'active' || !isAdmin
                ? dataFiltered.length
                : usersListRequests.length
            }
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            // dense={table.dense}
            // onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{' '}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: IUserItem[];
  comparator: (a: any, b: any) => number;
  filters: IUserTableFilters;
}) {
  const { leadInformation, status, updatedAt } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (leadInformation) {
    inputData = inputData.filter(
      (user) =>
        (user.firstName ?? '')
          .toLowerCase()
          .indexOf(leadInformation.toLowerCase()) !== -1,
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }

  if (updatedAt.length) {
    inputData = inputData.filter((user) => updatedAt.includes(user.updatedAt));
  }

  return inputData;
}

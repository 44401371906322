import { format } from 'date-fns';

import { useMemo, useCallback, useReducer } from 'react';
import axios from 'axios';
//
// import { DashboardValueProps } from '../types';
import { DashboardContext } from './dashboard-context';

// ----------------------------------------------------------------------

enum Types {
  LEADS = 'LEADS',
  LOADER = 'LOADER',
  STATS = 'STATS',
  ATTRIBUTES = 'ATTRIBUTES',
  LEAD_SUBMISSIONS = 'LEAD_SUBMISSIONS',
  CONTACTS_DASHBOARD = 'CONTACTS_DASHBOARD',
  COLUMNS = 'COLUMNS',
  UTM_PARAMETERS = 'UTM_PARAMETERS',
}

type DashboardProviderProps = {
  children: React.ReactNode;
};

const initialState: any = {
  leads: [],
  loading: true,
  stats: null,
  attributes: null,
  leadSubmissions: null,
  contactsDashboard: [],
  columns: [],
  utmParameters: [],
};

const reducer = (state: any, action: any) => {
  if (action.type === Types.LEADS) {
    return {
      ...state,
      loading: false,
      leads: action.payload.leads,
    };
  }

  if (action.type === Types.CONTACTS_DASHBOARD) {
    return {
      ...state,
      loading: false,
      contactsDashboard: action.payload.contactsDashboard,
    };
  }

  if (action.type === Types.COLUMNS) {
    return {
      ...state,
      columns: action.payload,
    };
  }

  if (action.type === Types.UTM_PARAMETERS) {
    return {
      ...state,
      utmParameters: action.payload,
    };
  }

  if (action.type === Types.LOADER) {
    return {
      loading: false,
    };
  }
  if (action.type === Types.STATS) {
    return {
      stats: action.payload,
    };
  }

  if (action.type === Types.ATTRIBUTES) {
    return {
      attributes: action.payload.attributes,
    };
  }

  if (action.type === Types.LEAD_SUBMISSIONS) {
    return {
      ...state,
      leadSubmissions: action.payload.leadSubmissions,
    };
  }
  return state;
};

export function DashboardProvider({ children }: DashboardProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // CREATE ACCOUNT
  const createAccount = useCallback(async (dataAccount: any) => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const res = await axios.post(
        'https://api.trakt.pro/api/accounts',
        {
          data: {
            ...dataAccount,
          },
        },
        {
          headers: {
            Origin: 'cpp.ca',
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.ATTRIBUTES,
        payload: {
          attributes: data.data.attributes,
        },
      });
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, []);

  // GET CONTACTS DASHBOARD
  const getContactsDashboard = useCallback(async (campaignId: any) => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const res = await axios.get(
        `https://api.trakt.pro/api/lead-submissions/contacts?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.CONTACTS_DASHBOARD,
        payload: {
          contactsDashboard: data.data,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  // GET LEAD SUBMISSIONS
  const getLeadSubmissions = useCallback(async (submissionId: number) => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const cid = sessionStorage.getItem('cid');
      const res = await axios.get(
        `https://crm-api.produktiv.agency/api/lead-submissions/${submissionId}?populate=*${
          cid !== 'null' ? `&cid=${cid}` : ''
        }`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.LEAD_SUBMISSIONS,
        payload: {
          leadSubmissions: data.data.attributes,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  // GET VISITORS
  const getLeads = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const res = await axios.get(
        'https://crm-api.produktiv.agency/api/visitors-and-submissions?cid=zxcvb',
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.LEADS,
        payload: {
          leads: data,
        },
      });
    } catch (e) {
      dispatch({
        type: Types.LOADER,
        payload: {
          loading: false,
        },
      });
    }
  }, []);

  // INVITE USER
  const inviteUser = useCallback(async (data: any) => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      await axios.post(
        'https://crm-api.produktiv.agency/api/user-invite/?cid=zxcvb',
        data,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // REQUEST USER
  const requestUser = useCallback(async (data: any) => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      await axios.post(
        'https://api.trakt.pro/api/requests/invite-email?cid=zxcvb',
        data,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // INVITE LINK
  const inviteLink = useCallback(async (data: any) => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const res: any = await axios.post(
        'https://api.trakt.pro/api/requests/invite-link?cid=zxcvb',
        data,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return res?.data?.payload?.link || null;
    } catch (e) {
      console.error(e);
    }

    return null;
  }, []);

  // CREATE VISITOR
  const createContact = useCallback(
    async (dataContact: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.post(
          'https://crm-api.produktiv.agency/api/visitors/?cid=zxcvb&test=qwer',
          {
            data: {
              ...dataContact,
              account_id: 2,
              leadsScore: Number(dataContact.leadsScore),
            },
          },
          {
            headers: {
              Origin: 'cpp.ca',
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getLeads();
      } catch (e) {
        console.error(e);
      }
    },
    [getLeads],
  );

  // DELETE VISITOR
  const deleteContact = useCallback(
    async (contactId: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.delete(
          `https://crm-api.produktiv.agency/api/visitors/${contactId}/?cid=zxcvb&test=qwer`,
          {
            headers: {
              Origin: 'cpp.ca',
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getLeads();
      } catch (e) {
        console.error(e);
      }
    },
    [getLeads],
  );

  // GET STATS
  const getStats = useCallback(async ({ startDate, endDate }: any) => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const res = await axios.get(
        `https://crm-api.produktiv.agency/api/dashboard-stats?cid=zxcvb${
          startDate && endDate
            ? `&fromDate=${format(
                new Date(startDate),
                'dd-MM-yyyy',
              )}&toDate=${format(new Date(endDate), 'dd-MM-yyyy')}`
            : ''
        }`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.STATS,
        payload: data,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  // EDIT VISITOR
  const editContact = useCallback(
    async (dataContact: any, contactId: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.put(
          `https://crm-api.produktiv.agency/api/visitors/${contactId}/?cid=zxcvb&populate=*`,
          {
            data: {
              ...dataContact,
              account_id: 2,
              leadsScore: Number(dataContact.leadsScore),
            },
          },
          {
            headers: {
              Origin: 'cpp.ca',
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getStats({});
      } catch (e) {
        console.error(e);
      }
    },
    [getStats],
  );

  // UPDATE LEAD CONFIDENCE
  const updateLeadConfidenceAndLeadScore = useCallback(
    async (leadConfidenceId: any, data: any, isScore?: boolean) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.put(
          `https://crm-api.produktiv.agency/api/lead-submission/${leadConfidenceId}/${
            isScore ? 'score' : 'confidence'
          }/?cid=zxcvb`,
          data,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getContactsDashboard({});
      } catch (e) {
        console.error(e);
      }
    },
    [getContactsDashboard],
  );

  // UPDATE LEAD BUDGET
  const updateLeadBudget = useCallback(
    async (leadId: any, data: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.put(
          `https://crm-api.produktiv.agency/api/lead-submission/${leadId}/budget/?cid=zxcvb`,
          data,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        getContactsDashboard({});
      } catch (e) {
        console.error(e);
      }
    },
    [getContactsDashboard],
  );

  // DELETE LEAD
  const deleteLead = useCallback(
    async (leadId: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.delete(
          `https://crm-api.produktiv.agency/api/lead-submission/${leadId}/delete/?cid=zxcvb`,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        getContactsDashboard({});
      } catch (e) {
        console.error(e);
      }
    },
    [getContactsDashboard],
  );

  // UPDATE LEAD PRIORITY
  const updateLeadPriority = useCallback(
    async (leadConfidenceId: any, data: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.put(
          `https://crm-api.produktiv.agency/api/lead-submission/${leadConfidenceId}/priority/?cid=zxcvb`,
          data,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getContactsDashboard({});
      } catch (e) {
        console.error(e);
      }
    },
    [getContactsDashboard],
  );

  // GET COLUMNS
  const getColumns = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const res = await axios.get(
        `https://api.trakt.pro/api/user-columns?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.COLUMNS,
        payload: data,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  // EDIT BUDGET STATUS
  const editBudgetStatus = useCallback(
    async (statusListId: any, data: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.put(
          `https://crm-api.produktiv.agency/api/lead-submission/${statusListId}/status?cid=zxcvb&populate=*`,
          data,
          {
            headers: {
              Origin: 'cpp.ca',
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        getColumns();
        getContactsDashboard({});
      } catch (e) {
        console.error(e);
      }
    },
    [getColumns, getContactsDashboard],
  );

  // CREATE COLUMN
  const createColumn = useCallback(
    async (columnName: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.post(
          'https://crm-api.produktiv.agency/api/account-kanban-columns/?cid=zxcvb',
          {
            data: {
              columnName,
            },
          },
          {
            headers: {
              Origin: 'cpp.ca',
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getColumns();
        getContactsDashboard({});
      } catch (e) {
        console.error(e);
      }
    },
    [getColumns, getContactsDashboard],
  );

  // DELETE COLUMN
  const deleteColumn = useCallback(
    async (columnId: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        const res = await axios.delete(
          `https://crm-api.produktiv.agency/api/account-kanban-columns/${columnId}/?cid=zxcvb`,
          {
            headers: {
              Origin: 'cpp.ca',
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getColumns();
        getContactsDashboard({});
        return res.data;
      } catch (e) {
        return e.response.data;
      }
    },
    [getColumns, getContactsDashboard],
  );

  // UPDATE KANBAN COLUMN
  const updateKanbanColumn = useCallback(
    async (columns: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.post(
          'https://crm-api.produktiv.agency/api/update-columns/?cid=zxcvb',
          {
            data: {
              columns,
            },
          },
          {
            headers: {
              Origin: 'cpp.ca',
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getColumns();
        getContactsDashboard({});
      } catch (e) {
        console.error(e);
      }
    },
    [getColumns, getContactsDashboard],
  );

  // UPDATE COLUMN NAME
  const updateColumnName = useCallback(
    async (columnId: any, columnName: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.put(
          `https://crm-api.produktiv.agency/api/account-kanban-columns/${columnId}?cid=zxcvb`,
          {
            data: {
              columnName,
            },
          },
          {
            headers: {
              Origin: 'cpp.ca',
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getColumns();
        getContactsDashboard({});
      } catch (e) {
        console.error(e);
      }
    },
    [getColumns, getContactsDashboard],
  );

  // LEAD COMMENTS
  const leadComments = useCallback(
    async (leadId: any, comment: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.post(
          'https://crm-api.produktiv.agency/api/lead-comments/?cid=zxcvb',
          {
            data: {
              leadId,
              comment,
            },
          },
          {
            headers: {
              Origin: 'cpp.ca',
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        getLeadSubmissions(leadId);
      } catch (e) {
        console.error(e);
      }
    },
    [getLeadSubmissions],
  );

  // GET UTM PARAMETERS
  const getUtmParameters = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const res = await axios.get(
        `https://crm-api.produktiv.agency/api/utm-parameters?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.UTM_PARAMETERS,
        payload: data,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  // CREATE CUSTOM SOURCE
  const createCustomSource = useCallback(
    async ({ title, value }: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.post(
          `https://crm-api.produktiv.agency/api/utm-sources?cid=zxcvb`,
          {
            data: {
              title,
              value,
            },
          },
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        getUtmParameters();
      } catch (e) {
        console.error(e);
      }
    },
    [getUtmParameters],
  );

  // CREATE CUSTOM MEDIUM
  const createCustomMedium = useCallback(
    async ({ title, value, utm_source }: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.post(
          `https://crm-api.produktiv.agency/api/utm-mediums?cid=zxcvb`,
          {
            data: {
              title,
              value,
              utm_source,
            },
          },
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        getUtmParameters();
      } catch (e) {
        console.error(e);
      }
    },
    [getUtmParameters],
  );

  // UPDATE CUSTOM SOURCE
  const updateCustomSource = useCallback(
    async ({ title, value, utmSourceId }: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.put(
          `https://crm-api.produktiv.agency/api/utm-sources/${utmSourceId}?cid=zxcvb`,
          {
            data: {
              title,
              value,
            },
          },
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  // UPDATE CUSTOM MEDIUM
  const updateCustomMedium = useCallback(
    async ({ title, value, utmMediumId }: any) => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        await axios.put(
          `https://crm-api.produktiv.agency/api/utm-sources/${utmMediumId}?cid=zxcvb`,
          {
            data: {
              title,
              value,
            },
          },
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  // ----

  const memoizedValue = useMemo(
    () => ({
      leads: state.leads,
      contactsDashboard: state.contactsDashboard,
      columns: state.columns,
      getLeads,
      loading: state.loading,
      createContact,
      deleteContact,
      editContact,
      getStats,
      editBudgetStatus,
      updateLeadConfidenceAndLeadScore,
      updateLeadPriority,
      updateLeadBudget,
      stats: state.stats,
      createAccount,
      attributes: state.attributes,
      leadSubmissions: state.leadSubmissions,
      getLeadSubmissions,
      getContactsDashboard,
      deleteLead,
      getColumns,
      createColumn,
      deleteColumn,
      updateKanbanColumn,
      updateColumnName,
      leadComments,
      getUtmParameters,
      utmParameters: state.utmParameters,
      createCustomSource,
      createCustomMedium,
      updateCustomSource,
      updateCustomMedium,
      inviteUser,
      requestUser,
      inviteLink,
    }),
    [
      getLeads,
      state.leads,
      state.loading,
      createContact,
      deleteContact,
      editContact,
      getStats,
      editBudgetStatus,
      updateLeadConfidenceAndLeadScore,
      updateLeadPriority,
      updateLeadBudget,
      state.stats,
      createAccount,
      state.attributes,
      state.leadSubmissions,
      state.contactsDashboard,
      state.columns,
      getLeadSubmissions,
      getContactsDashboard,
      deleteLead,
      getColumns,
      createColumn,
      deleteColumn,
      updateKanbanColumn,
      updateColumnName,
      leadComments,
      getUtmParameters,
      state.utmParameters,
      createCustomSource,
      createCustomMedium,
      updateCustomSource,
      updateCustomMedium,
      inviteUser,
      requestUser,
      inviteLink,
    ],
  );

  return (
    <DashboardContext.Provider value={memoizedValue}>
      {children}
    </DashboardContext.Provider>
  );
}

// @mui
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// components
import { useCampaignsContext } from 'src/components/campaigns';

// ----------------------------------------------------------------------

type Props = {
  onChange: (event: SelectChangeEvent) => void;
  value: string;
};

export default function RoleSelect({ onChange, value }: Props) {
  const { rolesList } = useCampaignsContext();

  return (
    <Select
      id="role-select"
      value={value}
      onChange={onChange}
      sx={{
        minWidth: '95px',
        '.MuiSelect-select': {
          p: '2px 6px',
          background: 'rgba(145, 158, 171, 0.16)',
          fontWeight: 700,
          lineHeight: '20px',
          fontSize: '12px',
          textTransform: 'capitalize',
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
    >
      {rolesList.map((item: any) => (
        <MenuItem value={item.id} sx={{ textTransform: 'capitalize' }}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
}
